import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Block, Content, Flex, FlexSpacer, Input, Yoga } from 'gerami'

import { VideosPageQuery } from '../../../../graphql-types'
import { useSearch } from '../../../shared/contexts/search-index-context/search-index-context'
import useSearchMatch from '../../../shared/hooks/use-search-match/use-search-match'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import VideoPlaylistCard from '../../components/video-playlist-card/video-playlist-card'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type VideosProps = {}

const Videos: React.FC<VideosProps> = ({}) => {
  const data = useStaticQuery<VideosPageQuery>(query)

  const [term, setTerm] = useState(``)
  const results = (useSearch(term, { expand: true }) || []).filter(
    result => result.template === 'video-playlist'
  )

  const featuredVideos = useSearchMatch(
    data.featuredVideos.edges.map(edge => edge.node),
    term.length ? results : undefined
  )
  const otherVideos = useSearchMatch(
    data.otherVideos.edges.map(edge => edge.node),
    term.length ? results : undefined
  )

  return (
    <>
      <SEO title="Videos" />

      <Layout>
        <Content transparent size="4XL">
          <Block first last>
            <Flex>
              <h1 className="h0">Videos</h1>

              <FlexSpacer />

              <div
                className="margin-left-very-big padding-top-big full-width"
                style={{ maxWidth: 420 }}
              >
                <Input
                  type="search"
                  value={term}
                  onChange={e => setTerm(e.target.value)}
                  placeholder="Search"
                  className="middle full-width"
                />
              </div>
            </Flex>

            {featuredVideos.length <= 0 ? null : (
              <>
                <h4 className="subtitle">
                  Featured Video
                  {featuredVideos.length === 1 ? `` : `s`}
                  {term ? (
                    <>
                      {' '}
                      Matching <q>{term}</q>
                    </>
                  ) : null}
                </h4>

                <hr />

                <Yoga maxCol={2} className="margin-bottom-big">
                  {featuredVideos.map((node, i) => (
                    <VideoPlaylistCard
                      key={i}
                      slug={node?.fields?.slug!}
                      thumbnail={node?.frontmatter?.thumbnail}
                      title={node?.frontmatter?.title!}
                      numberOfEpisodes={node.frontmatter?.episodes?.length || 0}
                      excerpt={node?.excerpt || ``}
                    />
                  ))}
                </Yoga>
              </>
            )}

            <h4 className="subtitle">
              {featuredVideos.length > 0 ? `Other` : `All`} Video
              {otherVideos.length === 1 ? `` : `s`}
              {term ? (
                <>
                  {' '}
                  Matching <q>{term}</q>
                </>
              ) : null}
            </h4>

            <hr />

            {otherVideos.length <= 0 ? (
              <div className="margin-vertical-big">
                No {featuredVideos.length > 0 ? `more ` : ``}
                videos found.
              </div>
            ) : (
              <Yoga maxCol={3}>
                {otherVideos.map((node, i) => (
                  <VideoPlaylistCard
                    key={i}
                    slug={node?.fields?.slug!}
                    thumbnail={node?.frontmatter?.thumbnail}
                    title={node?.frontmatter?.title!}
                    numberOfEpisodes={node.frontmatter?.episodes?.length || 0}
                    excerpt={node?.excerpt || ``}
                  />
                ))}
              </Yoga>
            )}
          </Block>
        </Content>

        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Videos

export const query = graphql`
  query VideosPage {
    featuredVideos: allMarkdownRemark(
      filter: {
        frontmatter: {
          template: { eq: "video-playlist" }
          isFeatured: { eq: true }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            date(formatString: "MMMM DD, YYYY")
            episodes
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    otherVideos: allMarkdownRemark(
      filter: {
        frontmatter: {
          template: { eq: "video-playlist" }
          isFeatured: { ne: true }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            date(formatString: "MMMM DD, YYYY")
            episodes
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
