import React from 'react'
import GatsbyImage from 'gatsby-image'
import { MdPlaylistPlay } from 'react-icons/md'

import './video-playlist-card.scss'
import Anchor from '../../../shared/components/anchor/anchor'

type VideoCardProps = {
  slug: string
  thumbnail?: string | null
  title: string
  numberOfEpisodes: number
  excerpt?: string | null
}

const VideoPlaylistCard: React.FC<VideoCardProps> = ({
  slug,
  thumbnail,
  title,
  numberOfEpisodes,
  excerpt
}) => {
  return (
    <Anchor to={slug} className="video-playlist-card">
      <GatsbyImage
        className="video-playlist-card-image bg-whitish"
        fluid={{
          aspectRatio: 16 / 9,
          src: `${thumbnail}?nf_resize=fit&w=512`,
          srcSet: `
            ${thumbnail}?nf_resize=fit&w=512 512w,
            ${thumbnail}?nf_resize=fit&w=320 320w,
            ${thumbnail}?nf_resize=fit&w=160 160w
          `,
          sizes: `
            (min-width: 36em) 33.3vw,
            100vw
          `
        }}
      />
      <div className="video-playlist-card-overlay">
        <MdPlaylistPlay className="video-playlist-card-overlay-icon" />
        <span className="video-playlist-card-overlay-label">Open Playlist</span>
      </div>
      <h5 className="video-playlist-card-title">
        {title}{' '}
        <span className="subtitle font-M"> — {numberOfEpisodes} Videos</span>
      </h5>
      <div className="video-playlist-card-excerpt fg-blackish">{excerpt}</div>
    </Anchor>
  )
}

export default VideoPlaylistCard
